import { useCallback, useState } from "react";
//
import { useNavigate } from "react-router-dom";
//
import { Button } from "@mui/material";
//Error handling
import { ErrorBoundary } from "react-error-boundary";
//
import styles from "./BasePage.module.css";
//
const fallback =
	(tryCnt, setTryCnt, navigate) =>
	({ resetErrorBoundary }) => {
		const retry = () => {
			setTryCnt((c) => c + 1);
			resetErrorBoundary();
		};
		//
        const toHome = useCallback(() => {
			setTryCnt(0);
			navigate("/");
		}, []);
		//
		return (
			<div className={styles.pageContentHolder}>
				<p>Something went wrong.</p>
				{tryCnt === 0 && <Button variant="contained" color="primary" onClick={retry}>Try Again</Button> }
                {tryCnt>0 && <Button variant="contained" color="primary" onClick={toHome}>Home Page</Button>}
			</div>
		);
	};
//
function BasePage({ children }) {
    const navigate = useNavigate();
	const [tryCnt, setTryCnt] = useState(0);
	return (
		<ErrorBoundary FallbackComponent={fallback(tryCnt, setTryCnt, navigate)} onError={(error) => console.error(error)}>
			<div className={styles.pageContentHolder}>{children}</div>
		</ErrorBoundary>
	);
}
//
export default BasePage;
