// Basic react imports
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
// Navigation imports
import { Navigate, HashRouter, Routes, Route } from "react-router-dom";
// Data Models
import UserRole from "./models/role";
// Logged out pages
import LoginPage from "./pages/LoginPage/LoginPage";
// Logged in pages
import BasicSuspense from "./components/BasicSuspense/BasicSuspense";
const AdminHomePage = React.lazy(() => import("./pages/AdminHomePage/AdminHomePage"));
const ClientHomePage = React.lazy(() => import("./pages/ClientHomePage/ClientHomePage"));
const SubClientHomePage = React.lazy(() => import("./pages/SubClientHomePage/SubClientHomePage"));
const UsageDetailsPage = React.lazy(() => import("./pages/UsageDetailsPage/UsageDetailsPage"));
const ClientPurchasePage = React.lazy(() => import("./pages/ClientPurchasePage/ClientPurchasePage"));
//
const basename = "/";
//
function App() {
	const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
	const userId = useSelector((state) => state.auth.userId);
	const role = useSelector((state) => state.auth.role);

	const pages = useMemo(() => {
		if (!isAuthenticated) {
			return (
				<>
					<Route exact path="/" element={<LoginPage />} />
					<Route exact path="*" element={<Navigate to="/" />} />
				</>
			);
		}
		return (
			<>
				{role === UserRole.ADMIN && (<>
					<Route exact path="/" element={BasicSuspense.wrap(<AdminHomePage />)} />
					<Route exact path="/details" element={BasicSuspense.wrap(<UsageDetailsPage />)} />				
					<Route exact path="/client/:clientId" element={BasicSuspense.wrap(<ClientHomePage />)} />				
					<Route exact path="/client/:clientId/:subClientId" element={BasicSuspense.wrap(<SubClientHomePage />)} />
					<Route exact path="/client/:clientId/product/:productId" element={BasicSuspense.wrap(<ClientPurchasePage />)} />
				</>)}
				{role === UserRole.CLIENT && (<>
					<Route exact path="/" element={<Navigate to={`/client/${userId}`} />} />
					<Route exact path={`/client/${userId}`} element={BasicSuspense.wrap(<ClientHomePage />)} />				
					<Route exact path={`/client/${userId}/:subClientId`} element={BasicSuspense.wrap(<SubClientHomePage />)} />
					<Route exact path={`/client/${userId}/product/:productId`} element={BasicSuspense.wrap(<ClientPurchasePage />)} />
				</>)}
				<Route exact path="*" element={<Navigate to="/" />} />
			</>
		);
	}, [isAuthenticated, userId, role]);

	return (
		<HashRouter basename={basename}>
			<Routes>{pages}</Routes>
		</HashRouter>
	);
}

export default App;
