import React, { useCallback, useRef, useState } from "react";
import { TextField, Button } from "@mui/material";
import BaseLoggedOutPage from "../BaseLoggedOutPage";
import styles from "./LoginPage.module.css";
import { authenticate } from "../../requests/authentication";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/store";
import LogoIcon from "./../../images/logo.png";
import ImpotentForm from "../../components/ImpotentForm/ImpotentForm";

function LoginPage() {
	const user = useRef();
	const password = useRef();
	const dispatch = useDispatch();
	const [error, setError] = useState(false);

	const onLogin = useCallback(() => {
		authenticate(user.current.value, password.current.value).then(([isOk, data]) =>
			isOk ? dispatch(authActions.login({ id: data["id"], token: data["token"], role: data["role"] })) : setError(true)
		);
	}, [dispatch]);

	return (
		<BaseLoggedOutPage>
			<div className={styles.blockUp}>
				<img src={LogoIcon} alt="ScanDocAI" />
			</div>
			<div className={styles.blockDown}>
				<ImpotentForm formStyle={styles.form} onSubmit={onLogin}>
					<TextField
						onChange={() => setError(false)}
						error={error}
						helperText={error ? "Please check the provided value" : " "}
						label="User"
						type="text"
						inputRef={user}
						fullWidth
					/>
					<TextField
						onChange={() => setError(false)}
						error={error}
						helperText={error ? "Please check the provided value" : " "}
						label="Password"
						type="password"
						inputRef={password}
						fullWidth
					/>
					<Button onClick={onLogin} variant="contained" color="primary">
						Login
					</Button>
				</ImpotentForm>
			</div>
		</BaseLoggedOutPage>
	);
}

export default LoginPage;
