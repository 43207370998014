import ReactDOM from "react-dom/client";
import App from "./App.jsx";
//
import {Provider} from "react-redux";
import store from "./store/store";
//
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
//
import "@fontsource/roboto";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "@fontsource/roboto/400.css";
//
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Provider store={store}><App /></Provider>);
