import { useCallback } from "react";

function ImpotentForm({ onSubmit, children, formStyle }) {
	const onFormSubmit = useCallback(
		(e) => {
			e.preventDefault();
			onSubmit(e);
		},
		[onSubmit]
	);

	return <form className={formStyle} onSubmit={onFormSubmit}>{children}</form>;
}

export default ImpotentForm;
