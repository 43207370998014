import { createSlice, configureStore } from "@reduxjs/toolkit";

//============================================================================
const defaultLoggedOutState = {
	isLoggedIn: false,
	userId: -1,
	token: "",
	role: undefined,
};

const authSlice = createSlice({
	name: "auth",
	initialState: defaultLoggedOutState,
	reducers: {
		login: (state, action) => {
			state.isLoggedIn = true;
			state.userId = action.payload.id;
			state.token = action.payload.token;
			state.role = action.payload.role;
		},
		logout: (state) => {
			state.isLoggedIn = false;
			state.userId = -1;
			state.token = "";
			state.role = undefined;
		},
	},
});

//============================================================================
const store = configureStore({
	reducer: {
		auth: authSlice.reducer,
	},
});

export const authActions = authSlice.actions;
export default store;
