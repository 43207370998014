const UserRole = Object.freeze({
    ADMIN:  "admin",
    CLIENT:  "client",
    get: (str) => {
        let key = str.toLowerCase();
        switch (key) {
            case UserRole.ADMIN:
                return UserRole.ADMIN
            case UserRole.CLIENT:
                return UserRole.CLIENT
            default:
                throw new Error(`Unknown role: ${str}`)
        }
    },
    all: () => [UserRole.ADMIN, UserRole.CLIENT],
    cnt: 2
});

export default UserRole;