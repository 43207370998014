import { backendURL } from "../config";
import UserRole from "../models/role";

export async function authenticate(user, pass) {
	const response = await fetch(`${backendURL}authenticate/`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			username: user,
			password: pass,
		}),
	});

	if (response.status === 200) {
		const data = await response.json();
		return [true, {...data, role: UserRole.get(data["role"])}];
	}
	return [false, {}];
}
